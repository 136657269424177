import React, { useState, useEffect } from 'react';
import { fetchProducts, Product } from '../productApi';
import ProductCard from './ProductCard';

const ProductList: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);

  useEffect(() => {
    const loadProducts = async () => {
      const fetchedProducts = await fetchProducts();
      setProducts(fetchedProducts);
      // Extract categories from description
      const uniqueCategories = ["All", ...new Set(fetchedProducts.map((p: Product) => {
        const match = p.description?.match(/Category:\s*([^\n]+)/);
        return match ? match[1] : 'Uncategorized';
      }))];
      setCategories(uniqueCategories as string[]);
    };
    loadProducts();
  }, []);

  useEffect(() => {
    const filtered = products.filter((product) => {
      const categoryMatch = selectedCategory === "All" || product.description?.includes(`Category: ${selectedCategory}`);
      const searchMatch = product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (product.description || '').toLowerCase().includes(searchTerm.toLowerCase());
      return categoryMatch && searchMatch;
    });
    setFilteredProducts(filtered);
  }, [products, selectedCategory, searchTerm]);

  return (
    <div>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border rounded"
        />
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          className="ml-2 p-2 border rounded"
        >
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredProducts.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default ProductList;