import React from 'react';
import ProductList from './components/ProductList';
import Header from './components/Header';

function App() {
  return (
    <div className="min-h-screen bg-gray-100">
      <Header />
      <main className="container mx-auto py-8">
        <h1 className="text-3xl font-bold mb-8">Buy It For Life Products</h1>
        <ProductList />
      </main>
    </div>
  );
}

export default App;