import React from 'react';
import { Product } from '../productApi';

interface ProductCardProps {
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  // Extract category from description (if available)
  const category = product.description?.match(/Category:\s*([^\n]+)/)?.[1] || 'Uncategorized';

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <img
        src="/placeholder.svg"
        alt={product.name}
        className="w-full h-48 object-cover"
      />
      <div className="p-4">
        <h2 className="text-xl font-bold mb-2">{product.name}</h2>
        <p className="text-sm text-gray-600 mb-2">{product.description}</p>
        <p className="font-bold">${product.price}</p>
        <p className="mt-2 text-sm text-gray-500">Category: {category}</p>
      </div>
    </div>
  );
};

export default ProductCard;