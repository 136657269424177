import axios from 'axios';

export interface Product {
  id: string;
  name: string;
  description: string;
  price: string;
  source_post_id: string;
}

const API_BASE_URL = 'http://localhost:3001/api';

export const fetchProducts = async (): Promise<Product[]> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/products`);
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    return [];
  }
};